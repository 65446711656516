import { Paper, Typography, Box, Grid, Divider } from "@mui/material";

const DisplayServices = ({classes, title, image, description, isIntoView, ...props }) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: { xs: '5%', md: '20%'},
        paddingLeft: { xs: '5%', md: '20%'},
        backgroundColor:"black",
      }}

      className={ isIntoView ? classes.serviceType : null}
    >
      <Grid item>
        <img src={image} width="50px" height="50px" marginLeft="auto" className={classes.imgDisplaySection}></img>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontStyle: "italic",
            fontWeight: "bolder",
            fontSize: "2.5rem",
            color:"white",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <Typography sx={{ textAlign: "center", color:"white", }}>{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default DisplayServices;
