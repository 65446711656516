import MediaControlCard from "./components/MediaControlCard"
import carInMud from "./../../assets/images/services/carInMud.jpg"
import carTowing from "./../../assets/images/services/towing.jpeg"
import remorcare from "./../../assets/images/services/remorcare.jpeg"
import { Grid } from "@mui/material";
export default function Services({classes, ...props}) {
 
    return (
        <Grid container flexDirection="row" spacing={2} sx={{ mt:0}} >
            <Grid item sx={{ width: "100vw"}}  className={classes.deblocare}>
                <MediaControlCard
                    title="Deblocare"
                    image={carInMud}
                    description="O descriere despre serviciu"
                />
            </Grid>
            <Grid item sx={{ width: "100vw"}} className={classes.remorcare}>
                <MediaControlCard
                    title="Remorcare"
                    image={remorcare}
                    description="O descriere despre serviciu"
                />
            </Grid>
            <Grid item sx={{ width: "100vw"}} className={classes.reparatie}>
                <MediaControlCard
                    title="Reparatie"
                    image={carInMud}
                    description="O descriere despre serviciu"
                />
            </Grid>
            <Grid item sx={{ width: "100vw"}} className={classes.tractare}>
                <MediaControlCard
                    title="Tractare"
                    image={carTowing}
                    description="O descriere despre serviciu"
                />
            </Grid>
            <Grid item sx={{ width: "100vw"}} className={classes.blocari}>
                <MediaControlCard
                    title="Blocari"
                    image={carInMud}
                    description="O descriere despre serviciu"
                    
                />
            </Grid>
            <Grid item sx={{ width: "100vw"}} className={classes.pornire}>
                <MediaControlCard
                    title="Pornire"
                    image={carInMud}
                    description="O descriere despre serviciu"
                    
                />
            </Grid>
        </Grid>
           
    );
  }