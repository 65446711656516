import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FlagIcon from "@mui/icons-material/Flag";
import AnimatedNumbers from "react-animated-numbers";
import image2 from "./../../../../../assets/images/home-page/vehicle_loading.jpg";

import { Paper, Typography, Box, Grid } from "@mui/material";

export const CustomerSatisfactionSection = () => { 
  return (
    <Paper elevation={0} square sx={{ position: "relative",backgroundColor:'black' }}>
      <img
        src={image2}
        alt="ss"
        style={{
          maxWidth: "90vw",
          minHeight: "45vh",
          maxHeight: "50vh",
          minWidth: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          position: "absolute",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{ minHeight: "100%", paddingRight: "5%", paddingLeft: "5%" }}
        >
          <Grid
            item
            xs={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <SentimentSatisfiedAltIcon
                sx={{ width: "30%", height: "30%", color: "white", mb: 1 }}
              />
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                <AnimatedNumbers
                  // includeComma
                  animateToNumber={1250}
                  // fontStyle={{ fontSize: 20 }}
                  configs={[
                    { mass: 1, tension: 220, friction: 100 },
                    { mass: 1, tension: 180, friction: 130 },
                    { mass: 1, tension: 280, friction: 90 },
                    { mass: 1, tension: 180, friction: 135 },
                    { mass: 1, tension: 260, friction: 100 },
                    { mass: 1, tension: 210, friction: 180 },
                  ]}
                />
              </Typography>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {" "}
                Clienti multumiti
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CarRepairIcon
                sx={{ width: "30%", height: "30%", color: "white", mb: 1 }}
              />
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                <AnimatedNumbers
                  // includeComma
                  animateToNumber={1250}
                  // fontStyle={{ fontSize: 20 }}
                  configs={[
                    { mass: 1, tension: 220, friction: 100 },
                    { mass: 1, tension: 180, friction: 130 },
                    { mass: 1, tension: 280, friction: 90 },
                    { mass: 1, tension: 180, friction: 135 },
                    { mass: 1, tension: 260, friction: 100 },
                    { mass: 1, tension: 210, friction: 180 },
                  ]}
                />
              </Typography>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {" "}
                Masini tractate
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <PeopleAltIcon
                sx={{ width: "30%", height: "30%", color: "white", mb: 1 }}
              />
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                <AnimatedNumbers
                  // includeComma
                  animateToNumber={6}
                  // fontStyle={{ fontSize: 20 }}
                  configs={[
                    { mass: 1, tension: 220, friction: 100 },
                    { mass: 1, tension: 180, friction: 130 },
                    { mass: 1, tension: 280, friction: 90 },
                    { mass: 1, tension: 180, friction: 135 },
                    { mass: 1, tension: 260, friction: 100 },
                    { mass: 1, tension: 210, friction: 180 },
                  ]}
                />
              </Typography>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {" "}
                Membrii in echipa
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FlagIcon
                sx={{ width: "30%", height: "30%", color: "white", mb: 1 }}
              />
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                <AnimatedNumbers
                  // includeComma
                  animateToNumber={new Date().getFullYear() - 2001}
                  // fontStyle={{ fontSize: 20 }}
                  configs={[
                    { mass: 1, tension: 220, friction: 100 },
                    { mass: 1, tension: 180, friction: 130 },
                    { mass: 1, tension: 280, friction: 90 },
                    { mass: 1, tension: 180, friction: 135 },
                    { mass: 1, tension: 260, friction: 100 },
                    { mass: 1, tension: 210, friction: 180 },
                  ]}
                />
              </Typography>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                {" "}
                Ani experienta
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
