import { Grid, TextField, Box, Typography } from "@mui/material";
import houseIcon from "./../../assets/images/contact-page/houseIcon.png";
import emailIcon from "./../../assets/images/contact-page/emailIcon.png";
import phoneIcon from "./../../assets/images/contact-page/phoneIcon.png";
import whatsappIcon from "./../../assets/images/contact-page/whatsappIcon.png";
import {PhoneNumber, PhoneNumberForDisplay} from '../../utils/local_variables';

const ContactPage = ({ classes, ...props }) => {
  return (
    <Grid container className={classes.gridCenteredContainer}>
      <Grid container direction="column" sx={{mb:5}}>
        <Grid item textAlign="center">
          <Typography className={classes.pageTitle}>Contactati-ne</Typography>
        </Grid>
        <Grid item textAlign="center">
          <Typography className={classes.pageSubTitle}>Va stam la dispozitie 24h din 24h </Typography>{" "}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridContactinfoContainer}>
        <Grid item xs={12} md={3} className={classes.gridItemContainer}>
          <img src={houseIcon} width="100px" height="100px" className={classes.img}/>
          <Typography className={classes.subtitle}>
            {" "}
            Buzau DN Maracineni{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.gridItemContainer}>
          <img src={emailIcon} width="100px" height="100px" className={classes.img}/>
          <Typography className={classes.subtitle}>
            contact@alex-tractari.ro
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.gridItemContainer}>
          <a href={`tel:${PhoneNumber}`}>
          <img src={phoneIcon} width="100px" height="100px" className={classes.img}/>
          </a>
          <Typography className={classes.subtitle}>{PhoneNumberForDisplay}</Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.gridItemContainer}>
          <img src={whatsappIcon} width="100px" height="100px" />
          <Typography className={classes.subtitle}>
            {PhoneNumberForDisplay}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default ContactPage;
