export default (theme) => ({
   deblocare: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '0s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },
    remorcare: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '0.5s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },
    reparatie: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '1s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },
    tractare: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '1.5s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },

    blocari: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '2s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },

    pornire: {
      left:'-100vw',
      position: 'relative',
      animationName: '$example',
      animationDuration: '2s',
      animationDelay: '2.5s',
      animationFillMode: 'forwards',
      [theme.breakpoints.down('md')]: {
         top:'200vh',
         left:'0px',
         animationName: '$example1',
       },
    },

   "@keyframes example" : {
      '0%':{ left:'-100vw'},
      '100%':{left:'0px'}
     },

     "@keyframes example1" : {
      '0%':{ top:'200vh'},
      '100%':{top:'0px'}
     },
   
  
   
  

  });
  