import React from "react";
import Carousel from "react-material-ui-carousel";
import "./styles.css";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import carInMud from "./../../../../assets/images/services/carInMud.jpg";
import carTowing from "./../../../../assets/images/services/towing.jpeg";
import remorcare from "./../../../../assets/images/services/remorcare.jpeg";

function Banner(props) {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  if (props.newProp) console.log(props.newProp);
  const contentPosition = props.contentPosition
    ? props.contentPosition
    : "left";
  const totalItems = greaterThanMid ? 2 : 1;
  //   const mediaLength = totalItems - 1;
  const mediaLength = totalItems;

  let items = [];
  //   const content = (
  //     <Grid item xs={12} md={12 / totalItems}  key="content">
  //       <CardContent className="Content">
  //         <Typography className="Title">{props.item.Name}</Typography>

  //         <Typography className="Caption">{props.item.Caption}</Typography>

  //         <Button variant="outlined" className="ViewButton">
  //           View Now
  //         </Button>
  //       </CardContent>
  //     </Grid>
  //   );

  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];

    const media = (
      <Grid item xs={12} md={12 / totalItems} key={item.Name}>
        <CardMedia className="Media" image={item.Image} title={item.Name}>
          {/* <Typography className="MediaCaption" >{item.Name}</Typography> */}
        </CardMedia>
      </Grid>
    );

    items.push(media);
  }

  //   if (contentPosition === "left") {
  //     items.unshift(content);
  //   } else if (contentPosition === "right") {
  //     items.push(content);
  //   } else if (contentPosition === "middle") {
  //     items.splice(items.length / 2, 0, content);
  //   }

  return (
    <Card raised className="Banner">
      <Grid container spacing={0} className="BannerGrid">
        {items}
      </Grid>
    </Card>
  );
}

const items = [
  {
    Name: "Pizza begin",
    Image: "https://source.unsplash.com/featured/?macbook",
    contentPosition: "left",
    Items: [
      {
        Name: "Dotari pentru Deblocare ",
        Image: carTowing,
      },
      {
        Name: "iPhone",
        Image: carInMud,
      },
    ],
  },
  {
    Name: "Home Appliances",
    Caption: "Say no to manual home labour!",
    contentPosition: "middle",
    Items: [
      {
        Name: "Washing Machine WX9102",
        Image: remorcare,
      },
      {
        Name: "Learus Vacuum Cleaner",
        Image: carInMud,
      },
    ],
  },
  {
    Name: "Home Appliances",
    Caption: "Say no to manual home labour!",
    contentPosition: "middle",
    Items: [
      {
        Name: "Washing Machine WX9102",
        Image: carInMud,
      },
      {
        Name: "Learus Vacuum Cleaner",
        Image: carTowing,
      },
    ],
  },
];

class BannerExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      animation: "fade",
      indicators: false,
      timeout: 250,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false,
      cycleNavigation: true,
    };
  }

  toggleAutoPlay() {
    this.setState({
      autoPlay: !this.state.autoPlay,
    });
  }

  toggleIndicators() {
    this.setState({
      indicators: !this.state.indicators,
    });
  }

  toggleNavButtonsAlwaysVisible() {
    this.setState({
      navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible,
    });
  }

  toggleNavButtonsAlwaysInvisible() {
    this.setState({
      navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible,
    });
  }

  toggleCycleNavigation() {
    this.setState({
      cycleNavigation: !this.state.cycleNavigation,
    });
  }

  changeAnimation(event) {
    this.setState({
      animation: event.target.value,
    });
  }

  changeTimeout(event, value) {
    this.setState({
      timeout: value,
    });
  }

  render() {
    return (
      <div
        style={{ marginTop: "5px", marginBottom: "5px", color: "#494949" }}
      >
        <Carousel
          className="Example"
          autoPlay={this.state.autoPlay}
          animation={this.state.animation}
          indicators={this.state.indicators}
          timeout={this.state.timeout}
          cycleNavigation={this.state.cycleNavigation}
          navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
        //   next={(now, previous) =>
        //     console.log(
        //       `Next User Callback: Now displaying child${now}. Previously displayed child${previous}`
        //     )
        //   }
        //   prev={(now, previous) =>
        //     console.log(
        //       `Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`
        //     )
        //   }
        //   onChange={(now, previous) =>
        //     console.log(
        //       `OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`
        //     )
        //   }
          // fullHeightHover={false}
          // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
          // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
          // indicatorContainerProps={{style: {margin: "20px"}}}
          // NextIcon='next'
        >
          {items.map((item, index) => {
            return (
              <Banner
                item={item}
                key={index}
                contentPosition={item.contentPosition}
              />
            );
          })}
        </Carousel>
      </div>
    );
  }
}

export default BannerExample;
