export default (theme) => ({
  title: {
    // fontSize: "2.25rem",
    // color: theme.palette.primary.main,
    "&.MuiTypography-root": {
      color: "white",
      fontSize: "8vw",
      fontStyle: "bold",
    },
  },
  button: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.primary.light,
    },
    "&.MuiButton-text": {
      color: "white",
    },
    animation: "$pulse-animation 1.5s infinite",

  },
  "@keyframes pulse-animation": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(255, 255, 255, 0.8)",
    },
    "100%": {
      boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)",
    },
  },

  homePageBoxContainerForTextAndButton: {
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    position: "absolute",
    top: 0,
    bottom: 0,
    marginTop: "auto",
    marginBottom: "auto",
    height: "50%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  imgDisplaySection: {
  //   "@media (prefers-color-scheme: dark)": {
  //     filter: "invert(1)",
  //   },
  //   "@media (prefers-color-scheme: light)": {
  //     filter: "invert(0)",
  //   },
  }, 
  serviceType :{
    animationName: '$example',
    animationDuration: '2s',
    animationDelay: '0s',
    animationFillMode: 'forwards',
  },
  "@keyframes example" : {
    '0%':{ transform: 'scale(0,0)'},
    '100%':{transform: 'scale(1,1)'}
   },
});
