import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import NavigationBar from "./navigation/index";
import HomePage from "./pages/home-page/index";
import ContactPage from "./pages/contact-page/index";
import Services from "./pages/services/index";

const defaultTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <Router>
      <NavigationBar />
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route exact path="/servicii" element={<Services/>} />
        <Route exact path="/service" element={<HomePage/>} />
        <Route exact path="/produse" element={<HomePage/>} />
        <Route exact path="/preturi" element={<HomePage/>} />
      </Routes>
    </Router>
  </ThemeProvider>
);

export default App;
