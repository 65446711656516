import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function MediaControlCard({title, image, description, ...props}) {
 
    return (
      <Card sx={{ display: 'flex', flexDirection:{ xs:"column" , md:"row"}, backgroundColor:"#000000e0" }}>
         <CardMedia
          component="img"
          sx={{ width: {md:'35vw', xs:"none"}}}
          image={image}
          alt="some image"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5" color="white">
              {title}
            </Typography>
            <Typography variant="subtitle1" color="white" component="div" >
              {description}
            </Typography>
          </CardContent>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            <IconButton aria-label="previous">
              dfsdf
            </IconButton>
            <IconButton aria-label="play/pause">
              fcdsf
            </IconButton>
            <IconButton aria-label="next">
              gvdfsd
            </IconButton>
          </Box> */}
        </Box>
      </Card>
    );
  }