import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsappForm from "./components/whatsapp-form";
import { useState } from "react";
import {PhoneNumber, PhoneNumberForDisplay} from '../../../../utils/local_variables';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width:{ xs:  '80%', md: '30%'},
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  display:'flex',
  justifyContent: 'center',
  flexDirection:'column',
};

export default function AssistanceModal({ handleClose, open, ...props }) {
    const [contactOptionWhatsapp, setContactOptionWhatsapp] = useState(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container>
                <Grid item xs={6} sx={{display:'flex', justifyContent:'center' }}>
                    <Button href={`tel:${PhoneNumber}`} onClick={()=> setContactOptionWhatsapp(false)} 
                        startIcon={<CallIcon />} 
                        sx={{'&:hover': { borderStyle:'solid', borderWidth:'1px', borderColor:'inherit', borderRadius:'6px' }, textAlign:'center'}}
                    > 
                        Suna
                    </Button>
                   
                </Grid>
                <Grid item xs={6}  sx={{display:'flex', justifyContent:'center' }}>
                    <Button onClick={()=> setContactOptionWhatsapp(!contactOptionWhatsapp)} 
                        endIcon={<WhatsAppIcon />} 
                        sx={{ color: '#4caf50', '&:hover': { borderStyle:'solid', borderWidth:'1px', borderColor:'inherit', borderRadius:'6px' }}}
                    > 
                        Whatsapp 
                    </Button>
                </Grid>
            </Grid>

            {contactOptionWhatsapp &&
                <Grid container sx={{mt:2, }}>
                    <Grid item xs={12}>  
                        <WhatsappForm handleClose={handleClose} number={PhoneNumber}/>
                    </Grid>

                </Grid>
            }       

        </Box>
      </Modal>
    </div>
  );
}
