import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import slider1 from "../../../assets/images/home-page/slider1.jpg";
import { Button, Typography, Box, Grid } from "@mui/material";
import MdPhone from "@mui/icons-material/Phone";
import AssistanceModal from "../components/modal/modal-assistence";
import { CustomerSatisfactionSection } from "../components/modal/components/customer-satisfaction";
import {TowingServicesSection} from "../components/modal/components/towing-services-section";
import {PageFooter} from "../components/modal/components/page-footer";
import BannerExample from "../components/carousel/component";


const HomePage = ({ classes, ...props }) => {
  const [open, setOpen] = useState(false);
  const handlePopUpAssistence = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Container maxWidth="100%" disableGutters={true}>
        <Paper elevation={0} square sx={{backgroundColor:'black'}}>
          <img
            src={slider1}
            alt="ss"
            style={{
              maxWidth: "-webkit-fill-available",
              minHeight: "95vh",
              objectFit: "cover",
            }}
          />

          <Box className={classes.homePageBoxContainerForTextAndButton}>
            <Typography className={classes.title} sx={{ mb: 2 }}>
              TRACTARI AUTO
            </Typography>
            <Typography sx={{ color: "white", mb: 5, textAlign:'center'}}>
              În ceea ce privește echipa noastră, există întotdeauna timp
              pentru oricare dintre vehiculele care au iesit de pe șosea sau care au
              probleme.
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              startIcon={<MdPhone />}
              onClick={handlePopUpAssistence}
            >
              Cere Asistenta
            </Button>
          </Box>
        </Paper>
        {/* <Box sx={{ minHeight:'20vh'}}>
          dsdfsdfsd
        </Box> */}
        <TowingServicesSection classes={classes} />
        <BannerExample></BannerExample>
        <CustomerSatisfactionSection />
        <PageFooter/>
      
      </Container>
      <AssistanceModal handleClose={handleClose} open={open} />
    </>
  );
};
export default HomePage;
