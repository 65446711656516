export default (theme) => ({
  logo: {
    width: "150px",
    height: "40px",
    borderRadius: "4px",
    display: { xs: "flex", md: "none" },
    marginRight: 1,
  },
  brandName: {
    // background:'#FF66CC',
    // width: '50px',
    // height: '35px',
    borderRadius: "3%",
    boxShadow: "1px 1px 1px 1px #0000001a",
    color:'white'
  },
  pulse: {
    animation: "$pulse-animation 2s infinite",
  },

  "@keyframes pulse-animation": {
    "0%": {
      boxShadow: "0 0 0 0px rgba(255, 255, 255, 0.5)",
    },
    "100%": {
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
  },
  arrow: {
    border: "solid red",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    height: "1px",
  },
  right: {
    transform: "rotate(-45deg)",
	position: 'relative',
	animation: '$moveToTheRight 1s alternate infinite',

  },
  left: {
    transform: "rotate(135deg)",
	position: 'relative',
	animation: '$moveToTheLeft 1s alternate infinite',
  },

 '@keyframes moveToTheLeft': {
	"0%":   {opacity: '100%', left:'0px', top:'0px'},
	"25%":  {opacity: '75%', left:'-10px', top:'0px'},
	"50%":  {opacity: '50%', left:'-15px', top:'0px'},
	"75%":  {opacity: '25%', left:'-20px', top:'0px'},
	"100%": {opacity: '0%', left:'-25px', top:'0px'}
  },
  
 '@keyframes moveToTheRight': {
	"0%":   {opacity: '100%', left:'0px', top:'0px'},
	"25%":  {opacity: '75%', left:'10px', top:'0px'},
	"50%":  {opacity: '50%', left:'15px', top:'0px'},
	"75%":  {opacity: '25%', left:'20px', top:'0px'},
	"100%": {opacity: '0%', left:'25px', top:'0px'}
  }
});
