export default (theme) => ({
  gridItemContainer: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    "&.MuiTypography-root": {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "10%",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "5%",
    },
  },
  gridContactinfoContainer: {
    [theme.breakpoints.up("xs")]: {
      paddingRight: "10%",
      paddingLeft: "10%",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  pageTitle: {
    "&.MuiTypography-root": {
      fontSize: "8vw",
    },
  },
  pageSubTitle: {
    "&.MuiTypography-root": {
      fontSize: "4vw",
    },
  },
  gridCenteredContainer: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      height: "100vh",
      alignContent: "center",
      justifyContent: "center",
      background: 'black',
      color:"white"
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  img: {
    // filter: "invert(1)",
    // [theme.breakpoints.between("lg", "xl")]: {
    //   "@media (prefers-color-scheme: dark)": {
    //     filter: "invert(0)",
    //   },
    // },
    // [theme.breakpoints.only("xl")]: {
    //   "@media (prefers-color-scheme: dark)": {
    //     filter: "invert(1)",
    //   },
    // },
    // [theme.breakpoints.down("xl")]: {
    //   "@media (prefers-color-scheme: dark)": {
    //     filter: "invert(1)",
    //   },
    // },
  },
});
