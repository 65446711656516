import { Paper, Typography, Box, Grid, Divider } from "@mui/material";
import DisplayServices from "../../display-services";
import pullImage from "../../../../../assets/images/home-page/pull.png";
import towTruck from "../../../../../assets/images/home-page/tow-truck.png";
import support from "../../../../../assets/images/home-page/support.png";
import shipping from "../../../../../assets/images/home-page/shipping.png";
import carRental from "../../../../../assets/images/home-page/car-rental.png";
import sparkPlug from "../../../../../assets/images/home-page/spark-plug.png";
import { useEffect, useRef, useState } from "react";

const useElementIsInView = () => {
  const containerRef = useRef(null);
  const [refIsInView, setRefIsInView] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    entry.isIntersecting && setRefIsInView(true);
  }
  useEffect(()=>{
    const observer = new IntersectionObserver(callbackFunction, {});

    if(containerRef.current) observer.observe(containerRef.current);

    return () =>{
      if(containerRef.current) observer.unobserve(containerRef.current);
    }
  },[containerRef])

  return [refIsInView, containerRef];
}

export const TowingServicesSection = ({ classes, ...props }) => {
  const [isIntoView, boxRef] = useElementIsInView();
 
  return (
    <Box sx={{paddingTop:5, paddingBottom:10, background:"black" }} ref={boxRef}>
      <Typography
        sx={{
          fontSize: { md: "4rem", xs: "2.5rem" },
          color: " rgb(2,0,0,0.4)",
          fontStyle: "italic",
          fontWeight: 900,
          textAlign: "center",
          color:"white",
        }}
      >
        Servicii Oferite
      </Typography>
      <Divider
        variant="middle"
        sx={{
          marginLeft: "20%",
          marginRight: "20%",
          borderBottomWidth: "thick",
          color:"white",
        }}
      />
      <Grid
        container
        sx={{
          paddingRight: { md: 12, xs: 0 },
          paddingLeft: { md: 12, xs: 0 },
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={pullImage}
            title="Deblocare"
            description=" Scoaterea unei mașini, care este blocată în noroi, murdărie, nisip
            sau orice alt tip de teren sau orice altceva - este ceea ce facem
            cel mai bine!"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={towTruck}
            title="Remorcare"
            description="  Dacă mașina dvs. se implică vreodată într-un accident nefericit și
            rămâne blocată, vom fi întotdeauna gata să vă venim în ajutor și să
            salvăm ziua!"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={support}
            title="Reparație"
            description="Ieșirea de pe șosea sau intrarea într-un accident rutier poate
            provoca daune tehnice foarte grave pieselor cele mai importante ale
            mașinii dumneavoastră."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={shipping}
            title="Tractare"
            description="Nu ar trebui să fie o surpriză atunci când remorcăm sau scoatem
            un vehicul, cu greutatea mare..."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={carRental}
            title="Blocări"
            description=" Cum unele chei ale mașinii funcționează defectuos iar cheile
            reprogramate nu funcționează întotdeauna, noi va venim in ajutor..."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100px",
            textAlign: "center",
            marginTop: { xs: "10px", md: 5 },
            padding: "0 1.5rem 0 1.5rem",
          }}
        >
          <DisplayServices
            isIntoView={isIntoView}
            classes={classes}
            image={sparkPlug}
            title="Pornire"
            description="Adesea, o mașină poate merge în teren și începe să funcționeze
            defectuos... Cel mai rău caz este ca motorul să se oprească chiar în
            mijlocul drumului."
          />
        </Grid>
      </Grid>
    </Box>
  );
};
