import React, {useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from '@mui/material/TextField';
import { Grid } from "@mui/material";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import IconButton from '@mui/material/IconButton';
import ReactWhatsapp from 'react-whatsapp';
import { useEffect } from "react";
import styled from 'styled-components';


  const SendButtonStyled = styled.button`
  background-color: #42a5f5;
  color: white;
  font-size: 15px;
  padding: 10px 60px;
  border-radius: 5px;
  border-color: #42a5f5;
  margin: 10px 0px; 
  cursor: pointer;
  :hover {
    background-color: #1976d2;
  }
`;

export default function WhatsappForm({handleClose,number, ...props}) {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('As avea nevoie de asistenta!');

    useEffect(()=> {
        const hour = new Date().getHours();
        let salutation = '';
        if  (hour >= 0 && hour <= 12) {
            salutation = 'Buna dimineata,'
        }else if (hour > 12 && hour <= 18){
            salutation = 'Buna ziua,'
        }else if (hour > 18  && hour <= 23) {
            salutation = 'Buna seara,'
        }else{
            salutation = 'Buna ziua,'
        }

        name != '' && setMessage(`${salutation}\nMa numesc ${name}, si am nevoie de asistenta auto.`)
    },[name])

  return (
    <>
        <Grid container>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'start',mb:1}}>
                <TextField
                    error={false}
                    id="filled-error"
                    label="Nume Prenume"
                    placeholder="Nume Prenume"
                    fullWidth
                    focused
                    small
                    value={name}
                    onChange={(e)=> setName(e.target.value) }
                />
            </Grid>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
                <TextField
                    error={false}
                    id="filled-error"
                    label="Mesaj"
                    value={message}
                    onChange={(e)=> setMessage(e.target.value)}
                    focused
                    fullWidth
                    small
                    minRows={3}
                    multiline
                />
            </Grid>

        </Grid>
        <Grid container sx={{display:'flex', justifyContent:'center', mt: 2}}>
            <Grid item  >
                <Button onClick={()=> handleClose()}>
                    <ReactWhatsapp number={number} message={message} element={SendButtonStyled} >
                            Send on Whatsapp
                    </ReactWhatsapp>
                </Button>
            </Grid>
        </Grid>
    </>
  );
}
