import { Paper, Typography, Box, Grid, Divider, Button } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";

export const PageFooter = () => {
    return (
        <Box sx={{backgroundColor:'black', padding: 2}}> 
            <Grid container direction="row" alignContent="center" 
                sx={{
                    borderRadius:"4px", 
                    backgroundColor:'#121212',
                    padding:1}}>
                <Grid item xs={12} md={5} textAlign="center" display="flex" direction="column" paddingLeft={2} paddingRight={2} sx={{color:"white"}}>
                    <Typography > UTILE </Typography>
                    <Divider></Divider>
                    <Button>Service</Button>
                    <Button>Produse</Button>
                    <Button>Parteneri</Button>
                </Grid>
                <Grid  item xs={12} md={4} textAlign="center" display="flex" direction="column" paddingLeft={2} paddingRight={2} sx={{color:"white"}}>
                <Typography> PRELUCRAREA DATELOR </Typography>
                <Divider></Divider>
                    <Button>GDPR</Button>
                    <Button>Cookies</Button>
                    
                </Grid>
                <Grid  item xs={12} md={3} display="flex" direction="column" paddingLeft={2} paddingRight={2} paddingTop={2} paddingBottom={2} sx={{borderRadius:3,}}>
                      <Card elevation={10} sx={{background:"#263238"}}>
                        <CardContent>
                            <Typography variant="h5" component="div"  sx={{color:"white"}}>
                                Date de contact
                            </Typography>
                            <Typography sx={{ my: 1.5 }} color="text.secondary"  sx={{color:"white"}}>
                                Adresa: Buzau
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary"  sx={{color:"white"}}>
                                Tel: (+40)722-967-147
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary"  sx={{color:"white"}}>
                                Whatsapp: (+40)722-967-147
                            </Typography>
                            <Typography  color="text.secondary"  sx={{color:"white"}}>
                                Email: contact@alex-tractari.ro
                            </Typography>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small" variant="contained" >Learn More</Button>
                        </CardActions> */}
                    </Card>
                    <Link to="/Contact" style={{ textDecoration: "none", display: "grid" }}>
                        <Button variant="contained" sx={{marginTop:1}}> Contact </Button>
                    </Link>
                </Grid>
            </Grid>
        </Box>
        
    );
}